<template>
  <div>

    <CoolLightBox 
      :items="logoImages" 
      :index="logoIndex"
      @close="logoIndex = null">
    </CoolLightBox>

    <CoolLightBox 
      :items="bannerImages" 
      :index="bannerIndex"
      @close="bannerIndex = null">
    </CoolLightBox>

    <CoolLightBox 
      :items="sliderImages" 
      :index="sliderIndex"
      @close="sliderIndex = null">
    </CoolLightBox>

    <CoolLightBox 
      :items="nameTagImages" 
      :index="nameTagIndex"
      @close="nameTagIndex = null">
    </CoolLightBox>

    <b-card>
    <h4 class="mb-2">
      <feather-icon
        icon="InfoIcon"
        size="18"
      /> &nbsp;
      Info
    </h4>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Name -->
        <b-col
          cols="12"
          md="8"
        >
          <div v-if="isView">
            <h5>Name</h5>
            <p>{{ eventData.name }}</p>
          </div>
          <b-form-group
            label="Name"
            label-for="name"
            v-if="!isView"
          >
            <b-form-input
              id="name"
              v-model="eventData.name"
              placeholder="Event name"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <div v-if="isView">
            <h5>Bold Information Display: </h5>
            <p>{{ eventData.use_bold_on_info }}</p>
          </div>
          
          <b-form-group
            label="Bold Information Display"
            label-for="bold-information-display"
            v-if="!isView"
          >
            <b-form-checkbox v-model="eventData.use_bold_on_info" name="bold-information-display" switch>
            </b-form-checkbox>
          </b-form-group>

        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <div v-if="isView">
            <h5>Close Registration: </h5>
            <p>{{ eventData.close_registration }}</p>
          </div>
          
          <b-form-group
            label="Close Registration"
            label-for="close-registration"
            v-if="!isView"
          >
            <b-form-checkbox v-model="eventData.close_registration" name="close-registration" switch>
            </b-form-checkbox>
          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Description -->
        <b-col
          cols="12"
          md="6"
        >
          <div v-if="isView">
            <h5>Description</h5>
            <p class="body-content" v-html="eventData.description" style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;"></p>
          </div>
          <b-form-group
            label="Description"
            label-for="description"
            v-if="!isView"
          >
            <b-overlay :show="isLoadingDescription" rounded="sm">
              <vue-editor 
                v-model="eventData.description" 
                useCustomImageHandler
                @image-added="handleImageAdded"
                :id="`description`"
              />
            </b-overlay>
            <!-- <b-form-input
              id="description"
              v-model="eventData.description"
              placeholder="Event description"
            /> -->
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <div v-if="isView">
            <h5>Info Concern (Under Submit Button)</h5>
            <p class="body-content" v-html="eventData.info_under_submit" style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;"></p>
          </div>
          <b-form-group
            label="Info Concern (Under Submit Button)"
            label-for="info-concern"
            v-if="!isView"
          >
            <b-overlay :show="isLoadingDescription" rounded="sm">
              <vue-editor 
                v-model="eventData.info_under_submit" 
                useCustomImageHandler
                @image-added="handleImageAdded"
                :id="`infoUnderSubmit`"
              />
            </b-overlay>
            <!-- <b-form-input
              id="description"
              v-model="eventData.description"
              placeholder="Event description"
            /> -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Location -->
        <b-col
          cols="12"
          md="4"
        >
          <div v-if="isView">
            <h5>Location</h5>
            <p>{{ eventData.location }}</p>
          </div>
          <b-form-group
            label="Location"
            label-for="location"
            v-if="!isView"
          >
            <b-form-input
              id="location"
              v-model="eventData.location"
              placeholder="Event location"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
        <div v-if="isView">
          <h5>Event Date</h5>
          <p>{{ formatDateReverse(eventData.date_start) + " - " + formatDateReverse(eventData.date_end) }}</p>
        </div>
        <b-form-group v-if="!isView">
          <label class="d-block">Event Date</label>
          <flat-pickr
            v-model="eventData.date"
            class="form-control"
            :config="dateConfig"
            placeholder="Pick a range of event date (from/to)"
            @on-close="updateItineraryDates"
          />
        </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <div v-if="isView">
            <h5>Date Display</h5>
            <p>{{ eventData.date_display }}</p>
          </div>
          <b-form-group
            label="Date Display"
            label-for="Date-Display"
            v-if="!isView"
          >
            <b-form-input
              id="Date-Display"
              v-model="eventData.date_display"
              placeholder="Date Display"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <div v-if="isView">
            <h5>Company</h5>
            <p>{{ (eventData.company !== null) ? eventData.company.name : '-' }}</p>
          </div>
          <b-form-group
            label="Company"
            label-for="event-company"
            v-if="!isView"
          >
            <v-select
              v-model="eventData.company_id"
              :options="companies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="event-company"
              placeholder="Pick a company"
            />
          </b-form-group>
        </b-col>

        <!--Field: Price-->
        <b-col
          cols="12"
          md="4"
        >
          <div v-if="isView">
            <h5>Price</h5>
            <p>{{ (eventData.price !== null) ? eventData.price : '0' }}</p>
          </div>
          <b-form-group
            label="Price"
            label-for="price"
            v-if="!isView"
          >
          <b-form-input
              id="price"
              v-model="eventData.price"
              placeholder="Event price"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Event Format -->
        <b-col
          cols="12"
          md="4"
        >
          <div v-if="isView">
            <h5>Event Format</h5>
            <p>{{ (eventData.type !== null) ? eventData.type.type : '-' }}</p>
          </div>
          <b-form-group
            label="Event Format"
            label-for="event-type"
            v-if="!isView"
          >
            <v-select
              v-model="eventData.event_type_id"
              :options="eventTypes"
              :reduce="val => val.value"
              :clearable="false"
              input-id="event-type"
              placeholder="Pick an event format"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Event Category -->
        <b-col
          cols="12"
          md="4"
        >
          <div v-if="isView">
            <h5>Category</h5>
            <p>{{ (eventData.category !== null) ? eventData.category.category : '-' }}</p>
          </div>
          <b-form-group
            label="Category"
            label-for="event-category"
            v-if="!isView"
          >
            <v-select
              v-model="eventData.event_category_id"
              :options="eventCategories"
              :reduce="val => val.value"
              :clearable="false"
              input-id="event-category"
              placeholder="Pick a category"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <div v-if="isView">
            <h5>Use Payment Gateway: </h5>
            <p>{{ eventData.use_payment_gateway }}</p>
          </div>
          
          <b-form-group
            label="Use Payment Gateway"
            label-for="use-payment-gateway"
            v-if="!isView"
          >
            <b-form-checkbox v-model="eventData.use_payment_gateway" name="use-payment-gateway" switch>
            </b-form-checkbox>
          </b-form-group>

        </b-col>
      </b-row>
      <b-row>

        <b-col cols="6" md="6">
          <div v-if="isView">
            <h5>Payment Information</h5>
            <p class="body-content" v-html="eventData.payment_info" style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;"></p>
          </div>
          <b-form-group
            label="Payment Information"
            label-for="payment-information"
            v-if="!isView"
          >
            <!-- <b-form-input
              id="successRegisterMessage"
              v-model="eventData.success_register_message"
              type="text"
              placeholder="Example: Thank you for register to this event."
            /> -->
            <b-overlay :show="isLoadingDescription" rounded="sm">
              <vue-editor 
                v-model="eventData.payment_info" 
                useCustomImageHandler
                @image-added="handleImageAdded"
                :id="`paymentinfo`"
              />
            </b-overlay>
          </b-form-group>
        </b-col>

        <b-col cols="6" md="6">
          <div v-if="isView">
            <h5>Success Register Message</h5>
            <p class="body-content" v-html="eventData.success_register_message" style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;"></p>
          </div>
          <b-form-group
            label="Success Register Message"
            label-for="success-register-message"
            v-if="!isView"
          >
            <!-- <b-form-input
              id="successRegisterMessage"
              v-model="eventData.success_register_message"
              type="text"
              placeholder="Example: Thank you for register to this event."
            /> -->
            <b-overlay :show="isLoadingDescription" rounded="sm">
              <vue-editor 
                v-model="eventData.success_register_message" 
                useCustomImageHandler
                @image-added="handleImageAdded"
                :id="`successRegisterMessage`"
              />
            </b-overlay>
          </b-form-group>
        </b-col>

      </b-row>
    
    <div>
      <h4 class="mt-2 mb-2">
        <feather-icon
          icon="ImageIcon"
          size="18"
        /> &nbsp;
        Images
      </h4>

      <b-row class="mt-2">
        <b-col cols="6">
          <label class="d-block">
            Logo
          </label>
          <b-form-file
            v-model="eventData.logo_blob"
            placeholder="Choose a logo image"
            accept="image/*"
            v-if="!isView"
          />

          <b-row>
            <b-col cols="12">
              <div v-if="(logoImages === null || logoImages.length == 0) && eventData.id" class="text-center mt-2">
                <div class="demo-spacing-0">
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="alert-body">
                      The logo's still empty
                    </div>
                  </b-alert>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="logoImages !== null && logoImages.length > 0" class="mt-2">

            <b-col
              v-for="(image, imageIndex) in logoImages"
              :key="imageIndex" class="text-center">
              <div class="image-wrapper" style="max-width: 300px">
                <span class="close" v-if="!isView"><a @click="removeLogoModal()">&times;</a></span>
                <a @click="logoIndex = imageIndex">
                  <img :src="image" style="max-width: 200px;"/>
                </a>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="6">
          <label class="d-block">
            Banner
          </label>
          <b-form-file
            v-model="eventData.banner_blob"
            placeholder="Choose a banner image"
            accept="image/*"
            v-if="!isView"
          />
          <b-row>
            <b-col cols="12">
              <div v-if="(bannerImages === null || bannerImages.length == 0) && eventData.id" class="text-center mt-2">
                <div class="demo-spacing-0">
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="alert-body">
                      The banner's still empty
                    </div>
                  </b-alert>
                </div>
              </div>
            </b-col>
          </b-row>
        
          <b-row v-if="bannerImages !== null && bannerImages.length > 0" class="mt-2">
            <b-col
              v-for="(image, imageIndex) in bannerImages"
              :key="imageIndex" class="text-center">
              <div class="image-wrapper" style="max-width: 300px">
                <span class="close" v-if="!isView"><a @click="removeBannerModal()">&times;</a></span>
                <a @click="bannerIndex = imageIndex">
                  <img :src="image" style="max-width: 200px;"/>
                </a>
              </div>

            </b-col>
          </b-row>
        </b-col>
      
      </b-row>

      <div>

        <h4 class="mt-2 mb-2">
          <feather-icon
            icon="BookmarkIcon"
            size="18"
          /> &nbsp;
          Name Tag
        </h4>

        <b-row class="mt-2">
          <b-col cols="6">
            <label class="d-block">
              Name Tag Image
            </label>
            <b-form-file
              v-model="eventData.nametag_blob"
              placeholder="Choose a name tag image"
              accept="image/*"
              v-if="!isView"
            />
            <b-row>
              <b-col cols="12">
                <div v-if="(nameTagImages === null || nameTagImages.length == 0) && eventData.id" class="text-center mt-2">
                  <div class="demo-spacing-0">
                    <b-alert
                      variant="warning"
                      show
                    >
                      <div class="alert-body">
                        The name tag image's still empty
                      </div>
                    </b-alert>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row v-if="nameTagImages !== null && nameTagImages.length > 0" class="mt-2">
              <b-col
                v-for="(image, imageIndex) in nameTagImages"
                :key="imageIndex" class="text-center">
                <div class="image-wrapper" style="max-width: 300px">
                  <span class="close" v-if="!isView"><a @click="removeNametagModal()">&times;</a></span>
                  <a @click="nameTagIndex = imageIndex">
                    <img :src="image" style="max-width: 200px;"/>
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <h5>
              Font File
            </h5>
            <b-form-file
              v-model="eventData.font_blob"
              placeholder="Choose a file"
              class="mb-1"
              v-if="!isView"
            />

            <b-row>
              <b-col cols="12">
                <div v-if="eventData.font_url === null && eventData.id !== null && isView" class="">
                  <p>-</p>
                </div>
              </b-col>
            </b-row>

            <b-row v-if="eventData.font_url !== null">
              <b-col>
                <p>
                  <a :href="eventData.font_url" target="_blank" class="">
                    Font link
                  </a>
                </p>
              </b-col>
            </b-row>
          </b-col>
          
          <b-col cols="4">
            <div v-if="isView">
              <h5>Font style</h5>
              <p>{{ (eventData.font_style !== null) ? eventData.font_style : '-' }}</p>
            </div>
            <b-form-group
              label="Font Style"
              label-for="font-style"
              v-if="!isView"
            >
              <v-select
                v-model="eventData.font_style"
                :options="fontStyleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="font-styles"
                placeholder="Pick a text style"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" md="4">
            <div v-if="isView">
              <h5>Font size</h5>
              <p v-if="eventData.font_size">{{ eventData.font_size }}</p>
              <p v-if="!eventData.font_size">-</p>
            </div>
            <b-form-group
              label="Font size"
              label-for="font-size"
              v-if="!isView"
            >
              <b-form-input
                v-model="eventData.font_size"
                type="number"
                placeholder="Font size (keep empty for default value)"
              />
            </b-form-group>
          </b-col>


          <b-col cols="6" md="4">
            <div v-if="isView">
              <h5>Font color</h5>
              <p v-if="eventData.font_color">{{ eventData.font_color }}</p>
              <p v-if="!eventData.font_color">-</p>
            </div>
            <b-form-group
              label="Font color"
              label-for="font-color"
              v-if="!isView"
            >
              <b-form-input
                v-model="eventData.font_color"
                type="url"
                placeholder="Font color (keep empty for default value)"
              />
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <div v-if="isView">
              <h5>First text appearance</h5>
              <p>{{ (eventData.company_first != null) ? ((eventData.company_first == 1) ? "Company name" : "Participant's name") : '-' }}</p>
            </div>
            <b-form-group
              label="First text appearance"
              label-for="font-color"
              v-if="!isView"
            >
              <v-select
                v-model="eventData.company_first"
                :options="companyFirstOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="company-first"
                placeholder="Pick the text that comes up first"
              />
            </b-form-group>
          </b-col>

        </b-row>

      </div>
      
      
    </div>

    </b-form>
    </b-card>

    <b-card>
      <div class="mt-1" v-if="$can('view', 'event_itineraries')">
        <div class="mt-1" v-if="!isView && eventData.id === null">
          <div style="float: left">
            <b-button
              variant="primary"
              @click="save()"
              :disabled="!isValid() || isLoading || isLoadingSlider"
              v-if="!eventData.id"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>{{ (isLoading) ? "Loading..." : "Save" }}</span>
            </b-button>
          </div>
          <div style="float: right">
            <b-button size="md" variant="outline-primary" @click="viewAllEvents()" :disabled="isLoadingSlider || isLoading">
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              /><span>Back</span>
            </b-button>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <div class="mt-1" v-if="!isView && eventData.id !== null">
          <div style="float: left">
            <b-button
              variant="primary"
              @click="save()"
              :disabled="!isValid() || isLoading"
              v-if="eventData.id && !isView"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>{{ (isLoading) ? "Loading..." : "Save Info" }}</span>
            </b-button>
          </div>

          <div style="float: right">
            <b-button size="md" variant="outline-primary" @click="viewAllEvents()" :disabled="isLoadingSlider || isLoading">
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              /><span>Back</span>
            </b-button>
          </div>
        </div>

        <div class="mt-2" v-if="isView">
          <div style="float: left">
            <b-button size="md" variant="primary" @click="editEvent()" v-if="$can('edit', 'events')">
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              /><span>Edit</span>
            </b-button>
          </div>
          <div style="float: right">
            <b-button size="md" variant="outline-primary" @click="viewAllEvents()" :disabled="isLoadingSlider || isLoading">
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              /><span>Back</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <!-- Action Buttons -->

    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showItineraryModal"
    >
      <template #modal-header="{}">
        <h5>{{ (tempItinerary.id === -1) ? "Add" : "Edit" }} Itinerary</h5>
      </template>

      <div v-if="isLoadingItineraryModal" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingItineraryModal">
        <b-form-group>
          <label for="date-time">Date & Time:</label>
          <flat-pickr
            v-model="tempItinerary.dateTime"
            class="form-control"
            :config="dateTimeItineraryConfig"
            placeholder="Pick the date & time for the itinerary"
          />
        </b-form-group>

        <b-form-group>
          <label for="label">Label:</label>
          <b-form-input
            id="label"
            type="text"
            v-model="tempItinerary.label"
            placeholder="Label"
          />
        </b-form-group>

        <b-form-group>
          <label for="information">Information:</label>
          <b-form-input
            id="information"
            type="text"
            v-model="tempItinerary.information"
            placeholder="Information"
          />
        </b-form-group>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="primary" @click="saveItinerary()" :disabled="!isValidItinerary() || isLoadingItineraryModal">
          {{ (!isLoadingItineraryModal) ? "Save" : "Loading..." }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-delete-itinerary"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteItineraryModal"
    >
      <template #modal-header="{}">
        <h5>Delete Itinerary</h5>
      </template>

      <p>
        Are you sure you want to delete this itinerary?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="removeItinerary()" :disabled="isLoadingDeleteItinerary">
          {{ (isLoadingDeleteItinerary) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-delete-slider"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteLogoModal"
    >
      <template #modal-header="{}">
        <h5>Delete Logo</h5>
      </template>

      <p>
        Are you sure you want to delete this image?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="removeLogo()" :disabled="isLoadingRemoveSlider">
          {{ (isLoadingRemoveSlider) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-delete-slider"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteBannerModal"
    >
      <template #modal-header="{}">
        <h5>Delete Banner</h5>
      </template>

      <p>
        Are you sure you want to delete this image?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="removeBanner()" :disabled="isLoadingRemoveSlider">
          {{ (isLoadingRemoveSlider) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-delete-nametag"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteNametagModal"
    >
      <template #modal-header="{}">
        <h5>Delete Nametag</h5>
      </template>

      <p>
        Are you sure you want to delete this image?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="removeNametag()" :disabled="isLoadingRemoveSlider">
          {{ (isLoadingRemoveSlider) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-delete-slider"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteSliderModal"
    >
      <template #modal-header="{}">
        <h5>Delete Slider Image</h5>
      </template>

      <p>
        Are you sure you want to delete this image?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="removeSlider()" :disabled="isLoadingRemoveSlider">
          {{ (isLoadingRemoveSlider) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-view-comments"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showCommentsModal"
      size="lg"
    >
      <template #modal-header="{}">
        <h5>Comments</h5>
      </template>

      <event-itinerary-comment-table :data="currentComments" />

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Close
        </b-button>
      </template>

    </b-modal>

      <b-modal
      id="modal-view-ratings"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showRatingsModal"
      size="lg"
    >
      <template #modal-header="{}">
        <h5>Ratings</h5>
      </template>

      <event-itinerary-rating-table :data="currentRatings" />

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Close
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-view-questions"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showQuestionsModal"
      size="lg"
    >
      <template #modal-header="{}">
        <h5>Questions</h5>
      </template>

      <event-itinerary-question-table :data="currentQuestions" />

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Close
        </b-button>
      </template>

    </b-modal>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormCheckboxGroup, BInputGroup, BInputGroupAppend, BFormFile, BAlert, BImg, BContainer, BSpinner, BButtonGroup, BDropdown, BDropdownItem, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import CoolLightBox from 'vue-cool-lightbox'
import EventItineraryQuestionTable from './EventItineraryQuestionTable.vue'
import EventItineraryCommentTable from './EventItineraryCommentTable.vue'
import EventItineraryRatingTable from './EventItineraryRatingTable.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { VueEditor } from "vue2-editor";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    flatPickr,
    moment,
    ToastificationContent,
    BAlert,
    CoolLightBox,
    BImg,
    BContainer,
    BSpinner,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    EventItineraryQuestionTable,
    EventItineraryCommentTable,
    EventItineraryRatingTable,
    VueEditor,
    BOverlay
  },
  data() {
    return {
      lastItineraryIndex: -1,
      isLoading: false,
      isLoadingForm: false,
      isLoadingSlider: false,
      isLoadingItinerary: false,
      showItineraryModal: false,
      showCommentsModal: false,
      showQuestionsModal: false,
      showRatingsModal: false,
      isEditItinerary: false,
      slidersTemp: null,
      isLoadingItineraryModal: false,
      isLoadingDeleteItinerary: false,
      isLoadingRemoveSlider: false,
      isLoadingDescription: false,
      showDeleteItineraryModal: false,
      showDeleteSliderModal: false,
      showDeleteLogoModal: false,
      showDeleteBannerModal: false,
      showDeleteNametagModal: false,
      selectedSlider: null,
      tempItinerary: {
        id: null,
        index: null,
        dateTime: null,
        label: null,
        information: null
      },
      currentSelectedItinerary: null,
      columns: ["event_date", "event_time", "label", "information", "actions"],
      dateConfig: {
        mode: 'range', 
        altFormat: 'Y-m-d H:i', 
        dateFormat: 'd-m-Y H:i', 
        minDate: null, 
        enableTime: true,
        defaultDate: []
      },
      registrationDateConfig: {
        mode: 'range', 
        altFormat: 'Y-m-d', 
        dateFormat: 'd-m-Y', 
        minDate: null,
        maxDate: null,
        defaultDate: []
      },
      dateTimeItineraryConfig: {
        altFormat: 'Y-m-d H:i', 
        dateFormat: 'd-m-Y H:i', 
        minDate: null, 
        enableTime: true
      },
      dateTimeConfig: {
        altFormat: 'Y-m-d H:i', 
        dateFormat: 'd-m-Y H:i', 
        minDate: null, 
        enableTime: true
      },
      bannerIndex: null,
      sliderIndex: null,
      logoIndex: null,
      nameTagIndex: null,
      bannerImages: [],
      sliderImages: [],
      logoImages: [],
      nameTagImages: [],
      field_ids: [],
      fieldGroupsFormatted: [],
      currentQuestions: [],
      currentRatings: [],
      currentComments: [],
      sliderFilenames: [],

      companyFirstOptions: [
        {
          label: "Company name",
          value: 1
        }, 
        {
          label: "Participant's name",
          value: 0
        }
      ],

      fontStyleOptions: [
        {
          label: "Capitalize first letter",
          value: "capitalize"
        },
        {
          label: "All uppercase",
          value: "uppercase"
        }, 
        {
          label: "All lowercase",
          value: "lowercase"
        }
      ],
    }
  },
  props: {
    isView: {
      type: Boolean,
      required: false,
      default: false
    },
    eventData: {
      type: Object,
      required: true,
    },
    eventTypes: {
      type: Array,
      required: true
    },
    eventCategories: {
      type: Array,
      required: true
    },
    companies: {
      type: Array,
      required: true
    },
    fieldGroups: {
      type: Array,
      required: true
    },
    // questions: {
    //   type: Array,
    //   required: true
    // }
  },
  computed: {
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {

      this.isLoadingDescription = true

      const requestData = new FormData()

      requestData.append('image', file);

      this.$http.post('/admin/v1/upload_image', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const data = res.data
              const currentData = data.data

              const url = currentData.url

              Editor.insertEmbed(cursorLocation, "image", url)
              
              resetUploader()

            }
            this.isLoadingDescription = false

        })

    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`
    },
    formatDate(value) {
      return moment(String(value), 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm')
    },
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm')
    },
    formatDateObj(value) {
      let year = value.getFullYear()
      let month = 0
      let date = 0
      let hour = 0
      let minutes = 0

      if(value.getMonth() < 10) {
        month = "0" + (value.getMonth() + 1)
      }
      else { 
        month = "" + (value.getMonth() + 1)
      }

      if(value.getDate() < 10) {
        date = "0" + value.getDate()
      }
      else { 
        date = value.getDate()
      }

      if(value.getHours() < 10) {
        hour = "0" + value.getHours()
      }
      else { 
        hour = value.getHours()
      }

      if(value.getMinutes() < 10) {
        minutes = "0" + value.getMinutes()
      }
      else { 
        minutes = value.getMinutes()
      }

      console.log(year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":00")

      return year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":00"

    },
    updateItineraryDates(values) {

      if(values.length > 1) {
        this.eventData.date_start = this.formatDateObj(values[0])
        this.eventData.date_end = this.formatDateObj(values[1])

        this.dateTimeItineraryConfig.minDate = this.formatDateReverse(this.eventData.date_start)
        this.dateTimeItineraryConfig.maxDate = this.formatDateReverse(this.eventData.date_end)

        this.registrationDateConfig.maxDate = this.formatDateReverse(this.eventData.date_start)
      }
    },
    viewRatingsModal(data) {
      this.showRatingsModal = true

      this.currentRatings = []

      data.ratings.forEach((item) => {
        this.currentRatings.push(item)
      })
    },
    viewCommentsModal(data) {
      this.showCommentsModal = true

      this.currentComments = []

      data.comments.forEach((item) => {
        this.currentComments.push(item)
      })
    },
    viewQuestionsModal(data) {
      this.showQuestionsModal = true

      this.currentQuestions = []

      data.questions.forEach((item) => {
        this.currentQuestions.push(item)
      })
    },
    editEvent() {
      this.$router.push('/events/' + this.eventData.id + '/edit')
    },
    viewAllEvents() {
      this.$router.push('/events')
    },
    deleteItineraryModal(item) {
      this.showDeleteItineraryModal = true
      this.currentSelectedItinerary = item
    },
    removeItinerary() {
      const id = this.currentSelectedItinerary.id
      const index = this.currentSelectedItinerary.index

      if(id !== null) {
        this.$http.delete('/admin/v1/event_itineraries/' + id)
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })
            }

            this.refreshItineraries()

            this.showDeleteItineraryModal = false
        })
      }
      else
      if(id === null && index !== null) {
        this.eventData.itineraries = this.eventData.itineraries.filter(function(item) {
            return item.index !== index
        })

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: "Itinerary berhasil dihapus",
          },
        })

        this.showDeleteItineraryModal = false
      }

    },
    editItineraryModal(item){
      this.currentSelectedItinerary = item

      const id = this.currentSelectedItinerary.id
      const index = this.currentSelectedItinerary.index

      this.isEditItinerary = true

      if(id !== null) {
        this.isLoadingItineraryModal = true

        this.showItineraryModal = true

        this.$http.get('/admin/v1/event_itineraries/' + id)
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const currentData = res.data.data
            
              this.tempItinerary = {
                id: id,
                index: index,
                dateTime: this.formatDateReverse(currentData.event_date) + " " + currentData.event_time,
                label: currentData.label,
                information: currentData.information
              }

              this.showItineraryModal = true
            }

            this.isLoadingItineraryModal = false
        })
      }
      else
      if(index !== null) {
        this.tempItinerary = {
          id: null,
          index: index,
          dateTime: item.event_date + " " + item.event_time,
          label: item.label,
          information: item.information
        }

        this.showItineraryModal = true
      }
    },
    isValidItinerary() {
      return this.tempItinerary.dateTime !== "" && this.tempItinerary.label !== "" && this.tempItinerary.information !== "" && this.tempItinerary.dateTime !== null && this.tempItinerary.label !== null && this.tempItinerary.information !== null
    },
    saveItinerary(){
      if(!this.isEditItinerary) {
        const successMessage = "Itinerary berhasil ditambahkan"
        const errorMessage = "Terdapat kesalahan ketika menambahkan itinerary"

        const dateTimeSplit = this.tempItinerary.dateTime.split(" ")

        if(dateTimeSplit.length == 2) {
          const itineraryDate = dateTimeSplit[0]
          const itineraryTime = dateTimeSplit[1]

          if(this.eventData.itineraries === null) {
            this.eventData.itineraries = []
          }

          const nextIndex = this.lastItineraryIndex + 1

          this.lastItineraryIndex = nextIndex

          const currentItinerary = {
            index: this.lastItineraryIndex,
            id: null,
            event_date: itineraryDate,
            event_time: itineraryTime,
            label: this.tempItinerary.label,
            information: this.tempItinerary.information
          }

          this.eventData.itineraries.push(currentItinerary)

          this.eventData.itineraries.sort(function(a, b){
            return moment(a.event_date + " " + a.event_time, 'DD-MM-YYYY H:i') - moment(b.event_date + " " + b.event_time, 'DD-MM-YYYY H:i')
          });

          this.eventData.itineraries.push({})
          this.eventData.itineraries.pop()

          this.showItineraryModal = false

          if(this.eventData.id !== null) {
            this.isLoadingItinerary = true
            this.isLoading = true

            this.$http.post('/admin/v1/events/' + this.eventData.id + '/itineraries', currentItinerary)
              .then(res => {
                const data = res.data
                const message = data.message

                if(!data.status) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: message,
                    },
                  })
                }
                else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: message,
                    },
                  })
                }

              this.isLoadingItinerary = false
              this.isLoading = false

              this.refreshItineraries()
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: successMessage,
              },
            })
          }
        }
        else {
          this.showItineraryModal = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: errorMessage,
            },
          })
        }
      }
      else {
        const successMessage = "Itinerary berhasil diubah"
        const errorMessage = "Terdapat kesalahan ketika mengubah itinerary"

        const dateTimeSplit = this.tempItinerary.dateTime.split(" ")

        if(dateTimeSplit.length == 2) {
          const itineraryDate = dateTimeSplit[0]
          const itineraryTime = dateTimeSplit[1]

          const currentItinerary = {
            index: this.tempItinerary.index,
            id: this.tempItinerary.id,
            event_date: itineraryDate,
            event_time: itineraryTime,
            label: this.tempItinerary.label,
            information: this.tempItinerary.information
          }

          if(this.eventData.id !== null) {
            this.isLoadingItinerary = true
            this.isLoading = true

            this.$http.put('/admin/v1/event_itineraries/' + this.tempItinerary.id, currentItinerary)
              .then(res => {
                const data = res.data
                const message = data.message

                if(!data.status) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: message,
                    },
                  })
                }
                else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: message,
                    },
                  })
                }

              this.isLoadingItinerary = false
              this.isLoading = false
              this.showItineraryModal = false

              this.refreshItineraries()
            })
          }
          else {
          if(this.eventData.itineraries !== null && this.eventData.itineraries.length > 0) {
            let isChanged = false

            this.eventData.itineraries.forEach((item, index) => {
              if(currentItinerary.index === item.index && !isChanged) {
                this.eventData.itineraries[index] = currentItinerary

                isChanged = true
              }
            })
          }

          this.eventData.itineraries.sort(function(a, b){
            return moment(a.event_date + " " + a.event_time, 'DD-MM-YYYY H:i') - moment(b.event_date + " " + b.event_time, 'DD-MM-YYYY H:i')
          });

          this.eventData.itineraries.push({})
          this.eventData.itineraries.pop()

          this.showItineraryModal = false

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: successMessage,
              },
            })
          }
        }
        else {
          this.showItineraryModal = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: errorMessage,
            },
          })
        }
      }
    },
    addItinerary() {
      this.showItineraryModal = true

      this.isEditItinerary = false

      this.tempItinerary = {
        id: null,
        dateTime: null,
        label: null,
        information: null
      }
    },
    clearSliders() {
      this.eventData.sliders_blob = []
      this.slidersTemp = null
    },
    onChangeSliders() {
      this.eventData.sliders_blob = []

      let filenames = []
      
      this.slidersTemp.forEach(currentFile => {
        if(!filenames.includes(currentFile.name)) {
          this.eventData.sliders_blob.push(currentFile)

          filenames.push(currentFile.name)
        }
      })
    },
    updateDates() {
      if(this.eventData.date) {
        const dates_split = this.eventData.date.split(" to ")

        if(dates_split.length == 2) {
          this.eventData.date_start = this.formatDate(dates_split[0])
          this.eventData.date_end = this.formatDate(dates_split[1])
        }
        else {
          this.eventData.date_start = this.formatDate(this.eventData.date)
          this.eventData.date_end = this.formatDate(this.eventData.date)
        }
      }

      if(this.eventData.registration_date) {
        const dates_split = this.eventData.registration_date.split(" to ")

        if(dates_split.length == 2) {
          this.eventData.start_registration_date = this.formatDate(dates_split[0])
          this.eventData.end_registration_date = this.formatDate(dates_split[1])
        }
        else {
          this.eventData.start_registration_date = this.formatDate(this.eventData.date)
          this.eventData.end_registration_date = this.formatDate(this.eventData.date)
        }
      }
    },
    save() {
      this.updateDates()

      const requestData = new FormData()

      const currentSlidersBlob = this.eventData.sliders_blob

      if(currentSlidersBlob !== null) {
        currentSlidersBlob.forEach((item, index) => {
            requestData.append('sliders[' + index + ']', item);
        })
      }

      if(this.eventData.banner_blob !== null) {
        requestData.append('banner', this.eventData.banner_blob)
      }

      if(this.eventData.logo_blob !== null) {
        requestData.append('logo', this.eventData.logo_blob)
      }

      if(this.eventData.file_blob !== null) {
        requestData.append('file', this.eventData.file_blob)
      }

      if(this.eventData.nametag_blob !== null) {
        requestData.append('nametag', this.eventData.nametag_blob)
      }

      if(this.eventData.font_blob !== null) {
        requestData.append('font', this.eventData.font_blob)
      }

      requestData.append('name', this.eventData.name)

      if(this.eventData.location !== null) {
        requestData.append('location', this.eventData.location)
      }

      if(this.eventData.description !== null) {
        requestData.append('description', this.eventData.description)
      }

      if(this.eventData.event_type_id !== null) {
        requestData.append('event_type_id', this.eventData.event_type_id)
      }

      if(this.eventData.event_category_id !== null) {
        requestData.append('event_category_id', this.eventData.event_category_id)
      }

      if(this.eventData.date_start !== null) {
        requestData.append('date_start', this.eventData.date_start )
      }

      if(this.eventData.date_end !== null) {
        requestData.append('date_end', this.eventData.date_end )
      }

      if(this.eventData.start_registration_date !== null) {
        requestData.append('start_registration_date', this.eventData.start_registration_date)
      }

      if(this.eventData.end_registration_date !== null) {
        requestData.append('end_registration_date', this.eventData.end_registration_date)
      }

      requestData.append('use_payment_gateway', (this.eventData.use_payment_gateway) ? 1 : 0)

      if(this.eventData.company_id !== null) {
        requestData.append('company_id', (this.eventData.company_id) ? this.eventData.company_id : null)
      }

      if(this.eventData.itineraries !== null) {
        requestData.append('itineraries', (this.eventData.itineraries) ? JSON.stringify(this.eventData.itineraries) : null)
      }

      if(this.eventData.price !== null) {
        requestData.append('price', this.eventData.price)
      }

      if(this.eventData.font_size !== null) {
        requestData.append('font_size', (this.eventData.font_size) ? this.eventData.font_size : null)
      }

      if(this.eventData.font_style !== null) {
        requestData.append('font_style', (this.eventData.font_style) ? this.eventData.font_style : null)
      }

      if(this.eventData.font_color !== null) {
        requestData.append('font_color', (this.eventData.font_color) ? this.eventData.font_color : null)
      }

      if(this.eventData.company_first !== null) {
        requestData.append('company_first', (this.eventData.company_first) ? this.eventData.company_first : null)
      }
      
      if(this.eventData.question_id !== null) {
        requestData.append('question_id', (this.eventData.question_id) ? this.eventData.question_id : null)
      }

      if(this.eventData.linkedin_link !== null) {
        requestData.append('linkedin_link',this.eventData.linkedin_link)
      }

      if(this.eventData.facebook_link !== null) {
        requestData.append('facebook_link',this.eventData.facebook_link)
      }

      if(this.eventData.linkedin_link !== null) {
        requestData.append('linkedin_link', this.eventData.linkedin_link)
      }

      if(this.eventData.instagram_link !== null) {
        requestData.append('instagram_link', this.eventData.instagram_link)
      }

      if(this.eventData.web_link !== null) {
        requestData.append('web_link', this.eventData.web_link)
      }

      if(this.eventData.success_register_message !== null) {
        requestData.append('success_register_message', this.eventData.success_register_message)
      }

      if(this.eventData.payment_info !== null) {
        requestData.append('payment_info', this.eventData.payment_info)
      }

      if(this.eventData.info_under_submit !== null) {
        requestData.append('info_under_submit', this.eventData.info_under_submit)
      }

      if(this.eventData.use_bold_on_info) {
        requestData.append('use_bold_on_info', 1)
      }else{
        requestData.append('use_bold_on_info', 0)
      }

      if(this.eventData.close_registration) {
        requestData.append('close_registration', 1)
      }else{
        requestData.append('close_registration', 0)
      }

      if(this.eventData.date_display !== null) {
        requestData.append('date_display', (this.eventData.date_display) ? this.eventData.date_display : null)
      }
      requestData.append('field_ids', Array.from(new Set(this.field_ids)))

      const requestHeader = { headers: { 'Content-Type': 'multipart/form-data' }}

      this.isLoading = true

      let requestURL = "/admin/v1/events"

      if(this.eventData.id !== null) {
        requestURL = "/admin/v1/events/" + this.eventData.id + "?_method=PUT"
      }

      this.$http.post(requestURL, requestData, requestHeader)
        .then(res => {
          const data = res.data
          const message = data.message
          const currentData = data.data

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })
            
            if(currentData !== null) {
              this.eventData.id = currentData.event_id

              this.$router.push('/events/' + this.eventData.id + '/edit')
            }

            this.refreshInfo()

          }

          this.isLoading = false
      })      
    },
    isValid() {
      return this.eventData.name !== null && this.eventData.name !== "" 
              // && this.eventData.price !== null && this.eventData.price !== "" 
              && ((this.eventData.date !== null && this.eventData.date !== "") || (this.eventData.date_start !== null && this.eventData.date_end !== ""))
              // && ((this.eventData.registration_date !== null && this.eventData.registration_date !== "") || (this.eventData.start_registration_date !== null && this.eventData.end_registration_date !== ""))
              && this.eventData.event_type_id !== null && this.eventData.event_type_id !== ""
              && this.eventData.event_category_id !== null && this.eventData.event_category_id !== ""
              && this.eventData.company_id !== null && this.eventData.company_id !== ""
    },
    removeLogo() {
      this.isLoadingRemoveSlider = true

      this.$http.delete('/admin/v1/events/' + this.eventData.id + '/remove_logo')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.logoImages = []

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })
          }

          this.isLoadingRemoveSlider = false
          this.showDeleteLogoModal = false
      })
      
    },
    removeBanner() {
      this.isLoadingRemoveSlider = true

      this.$http.delete('/admin/v1/events/' + this.eventData.id + '/remove_banner')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.bannerImages = []

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })
          }

          this.isLoadingRemoveSlider = false
          this.showDeleteBannerModal = false
      })
      
    },
    removeNametag() {
      this.isLoadingRemoveSlider = true

      this.$http.delete('/admin/v1/events/' + this.eventData.id + '/remove_nametag')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.nameTagImages = []

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })
          }

          this.isLoadingRemoveSlider = false
          this.showDeleteNametagModal = false
      })
      
    },
    removeSlider() {
      if(this.selectedSlider !== null) {
        this.isLoadingRemoveSlider = true

        const sliderId = this.selectedSlider.id

        this.$http.delete('/admin/v1/event_sliders/' + sliderId)
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const sliderURL = this.selectedSlider.slider_url

              this.sliderImages = this.sliderImages.filter(function(item) {
                return item !== sliderURL
              })

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })
            }

            this.isLoadingRemoveSlider = false
            this.showDeleteSliderModal = false
        })
      }
    },
    removeLogoModal() {
      this.showDeleteLogoModal = true
    },
    removeBannerModal() {
      this.showDeleteBannerModal = true
    },
    removeNametagModal() {
      this.showDeleteNametagModal = true
    },
    removeSliderModal(image) {
      this.showDeleteSliderModal = true

      const foundSlider = this.eventData.sliders.filter(function(item) {
        return item.slider_url === image
      })
      
      if(foundSlider.length > 0) {
        this.selectedSlider = foundSlider[0]
      }

    },
    saveSliders() {
      this.isLoadingSlider = true

      const requestData = new FormData()

      const currentSlidersBlob = this.eventData.sliders_blob

      if(currentSlidersBlob !== null) {
        currentSlidersBlob.forEach((item, index) => {
            requestData.append('sliders[' + index + ']', item);
        })
      }
      
      const requestHeader = { headers: { 'Content-Type': 'multipart/form-data' }}

      this.$http.post('/admin/v1/events/' + this.eventData.id + '/sliders', requestData, requestHeader)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })
          }

          this.isLoadingSlider = false

          this.refreshSliders()
      })    
    },
    refreshSliders() {
      this.isLoadingSlider = true

      this.$http.get('/admin/v1/events/' + this.eventData.id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventData.sliders = currentData.sliders

            this.previewSliders()
          }

          this.isLoadingSlider = false
      })
    },
    previewNameTag() {
      if(this.eventData.nametag_url !== null) {
        this.nameTagImages = []

        this.nameTagImages.push(this.eventData.nametag_url)
      }
    },
    previewBanner() {
      if(this.eventData.banner_url !== null) {
        this.bannerImages = []

        this.bannerImages.push(this.eventData.banner_url)
      }
    },
    previewLogo() {
      if(this.eventData.logo_url !== null) {
        this.logoImages = []

        this.logoImages.push(this.eventData.logo_url)
      }
    },
    previewSliders() {
      if(this.eventData.sliders !== null && this.eventData.sliders.length > 0) {
        this.sliderImages = []

        this.eventData.sliders.forEach((item, index) => {
          this.sliderImages.push(item.slider_url)
        })
      }
    },
    refreshInfo() {
      this.isLoadingItinerary = true

      this.$http.get('/admin/v1/events/' + this.eventData.id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventData.banner_url = currentData.banner_url
            this.eventData.logo_url = currentData.logo_url
            this.eventData.nametag_url = currentData.nametag_url

            this.previewBanner()
            this.previewLogo()
            this.previewNameTag()
          }

          this.isLoadingItinerary = false
      })
    },
    refreshItineraries() {
      this.isLoadingItinerary = true

      this.$http.get('/admin/v1/events/' + this.eventData.id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventData.itineraries = currentData.itineraries

            this.eventData.itineraries.push({})
            this.eventData.itineraries.pop()
          }

          this.isLoadingItinerary = false
      })
    },
  },
  created() {    
    if(this.eventData.date_start !== null && this.eventData.date_end !== null) {
      this.dateConfig.defaultDate = [ this.formatDateReverse(this.eventData.date_start), this.formatDateReverse(this.eventData.date_end) ]

      this.dateTimeItineraryConfig.minDate = this.formatDateReverse(this.eventData.date_start)
      this.dateTimeItineraryConfig.maxDate = this.formatDateReverse(this.eventData.date_end)
    }
    else {
      this.dateConfig.minDate = "today"
    }

    if(this.eventData.start_registration_date !== null && this.eventData.date_end !== null) {
      this.registrationDateConfig.defaultDate = [ this.formatDateReverse(this.eventData.start_registration_date), this.formatDateReverse(this.eventData.end_registration_date) ]
      
    }
    else {
      this.dateConfig.minDate = "today"
    }

    this.fieldGroupsFormatted = []

    this.fieldGroups.forEach((item) => {
      item.fields.forEach((field) => {
        this.fieldGroupsFormatted.push({
          text: item.name + " - " + field.name,
          value: field.id
        })
      })
    })

    if(this.eventData.fields !== null) {
      this.eventData.fields.forEach((item) => {
        this.field_ids.push(item.id)
      })
    }

    if(this.eventData.id === null) {
        this.clearSliders()
      }
    
    this.previewBanner()
    this.previewSliders()
    this.previewLogo()
    this.previewNameTag()

  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.image-wrapper {
    position: relative;
    font-size: 0;
}
.image-wrapper .close {
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 100;
    background-color: #cccccc;
    padding: 5px 3px;
    color: #373737;
    font-weight: bold;
    cursor: pointer;
    opacity: .5;
    text-align: center;
    font-size: 28px;
    line-height: 10px;
    border-radius: 50%;
}
.image-wrapper-wrap:hover .close {
    opacity: 1;
}

.custom-control.custom-checkbox {
  /* margin-top: 12px; */
  margin-bottom: 12px;
}

.additional-fields {
  max-height: 180px; 
  overflow: auto; 
  border: 1px solid #d8d6de; 
  padding: 12px; 
  border-radius: 8px; 
  margin-left: 12px; 
  max-width: 98%;
}
</style>
